


















import { calendarStore, meetingsStore, teamsStore } from '@/store'
import { Contact } from '@tada-team/tdproto-ts'
import { date } from 'quasar'
import { contacts } from 'td-api'
import { Component, Vue, Watch } from 'vue-property-decorator'

const { formatDate } = date

@Component({
  components: {
    CalendarControlSection: () => import('./ControlSection/index.vue'),
    DraggableResizable: () => import('@/components/UI/DraggableResizable.vue'),
    Main: () => import('./Main/index.vue'),
    MeetingDialog: () => import('../Meetings/MeetingDialog/index.vue'),
  },
})
export default class Calender extends Vue {
  private date = formatDate(new Date(), 'YYYY/MM/DD')
  private contact: Contact | null = null

  async created () {
    if (this.$route.params.contactId) {
      this.contact = await contacts.getContact(
        teamsStore.getters.currentTeam.uid,
        this.$route.params.contactId,
      )
    }

    await this.loadMeetings()
    await this.loadDates()
  }

  async changeCalendarDate (calendarDate: string): Promise<void> {
    const newDate = formatDate(calendarDate, 'YYYY/MM/DD')
    if (newDate === this.date) return
    this.date = newDate
    await this.loadMeetings()
  }

  async loadDates (): Promise<void> {
    const filter = this.contact ? [this.contact.jid] : undefined
    await meetingsStore.actions.loadMeetingsDates(filter)
  }

  async loadMeetings (): Promise<void> {
    await meetingsStore.actions.loadMeetings({
      ...this.interval,
      membersJids: this.contact
        ? [this.contact.jid]
        : [teamsStore.getters.currentTeam.me.jid],
    })
  }

  get meetings () {
    return meetingsStore.state.meetings
  }

  @Watch('viewMode')
  async changeViewMode () {
    await this.loadMeetings()
  }

  @Watch('$route.params.contactId')
  async onChangeContactId () {
    if (!this.$route.params.contactId) return
    this.contact = await contacts.getContact(
      teamsStore.getters.currentTeam.uid,
      this.$route.params.contactId,
    )
  }

  @Watch('contact')
  async changeContact (
    newContact: Contact | undefined,
    oldContact: Contact | undefined,
  ) {
    if (newContact?.jid === oldContact?.jid) return
    if (this.$route.params.contactId !== this.contact?.jid) {
      const teamId = teamsStore.getters.currentTeam.uid
      const contactId = this.contact?.jid

      this.$router.push({
        name: 'Calendar',
        params: contactId ? { teamId, contactId } : { teamId },
      })

      await this.loadMeetings()
      await this.loadDates()
    }
  }

  get viewMode () {
    return calendarStore.state.viewMode
  }

  get interval (): { startDate: string; endDate: string } {
    const selectedDate = new Date(this.date)

    const interval = {
      startDate: '',
      endDate: '',
    }

    if (this.viewMode === 'day') {
      const startDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0)
      const endDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1, 0, -1)
      interval.startDate = startDate.toISOString()
      interval.endDate = endDate.toISOString()
    } else if (this.viewMode === 'week') {
      const startDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate() - selectedDate.getDay() + 1,
        0,
        0,
        0,
      )
      const endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + 7,
        0,
        0,
        -1,
      )
      interval.startDate = startDate.toISOString()
      interval.endDate = endDate.toISOString()
    } else if (this.viewMode === 'month') {
      const startDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1,
        0,
        0,
        0,
      )
      const endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        0,
        0,
        0,
        -1,
      )
      interval.startDate = startDate.toISOString()
      interval.endDate = endDate.toISOString()
    }
    return interval
  }
}
